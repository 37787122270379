<template>
  <div>
    <h4 class="mb-6">{{ $t("message.title-order") }}</h4>
    <form @submit.prevent="submitFilter">
      <v-row no-gutters align="center" justify="space-between">
        <v-col cols="10">
          <v-row>
            <v-col cols="12" md="4" xs="6">
              <span>{{ $t("message.label-merchant") }}</span>
              <v-select
                v-model="filter.merchant"
                :items="merchantList"
                item-text="merchant_name"
                item-value="id"
                class="mt-1"
                outlined
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12" md="4" xs="6">
              <span>{{ $t("message.label-shop") }}</span>
              <v-select
                class="mt-1"
                :items="shopList"
                outlined
                item-text="store_name"
                item-value="store_name"
                v-model="filter.store"
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12" md="4" xs="6">
              <span>{{ $t("message.label-order-id") }}</span>
              <v-text-field
                class="mt-1"
                v-model="filter.orderId"
                type="text"
                outlined
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" xs="6">
              <span>{{ $t("message.label-status") }}</span>
              <v-select
                class="mt-1"
                :items="itemsStatus"
                outlined
                item-text="name"
                item-value="id"
                v-model="filter.status"
                clearable
              ></v-select>
            </v-col>
            <v-col class="font-label-class" cols="6" md="4" xs="4">
              <span>{{ $t("message.label-amount") }}</span>
              <v-row no-gutters class="mt-1">
                <v-col>
                  <v-text-field
                    type="number"
                    v-model="filter.amountFrom"
                    outlined
                    clearable
                  />
                </v-col>
                <v-col class="minus-amount">
                  <v-icon class="icon-tilde-class" size="18">mdi-tilde</v-icon>
                </v-col>
                <v-col>
                  <v-text-field
                    type="number"
                    v-model="filter.amountTo"
                    clearable
                    outlined
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="5" xs="6">
              <span>{{ $t("message.label-order-datetime") }}</span>
              <v-row no-gutters class="mt-1">
                <v-col>
                  <!-- <v-dialog
                    ref="dialog"
                    v-model="modalFrom"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filter.dateFrom"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.dateFrom" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modalFrom = false">
                        {{ $t("message.btn-cancel") }}
                      </v-btn>
                      <v-btn text color="primary" @click.prevent="submitDateFrom">
                        {{ $t("message.btn-ok") }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog> -->

                  <v-menu
                    v-model="modalFrom"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class=""
                        outlined
                        v-model="filter.dateFrom"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        style="font-size: 14px;"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.dateFrom"
                      @input="modalFrom = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="minus-amount">
                  <v-icon class="icon-tilde-class" size="18">mdi-tilde</v-icon>
                </v-col>
                <v-col>
                  <!-- <v-dialog
                    ref="dialog"
                    v-model="modalTo"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filter.dateTo"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.dateTo" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modalTo = false">
                        {{ $t("message.btn-cancel") }}
                      </v-btn>
                      <v-btn text color="primary" @click="submitDateTo">
                        {{ $t("message.btn-ok") }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog> -->

                  <v-menu
                    v-model="modalTo"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class=""
                        outlined
                        clearable
                        v-model="filter.dateTo"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        style="font-size: 14px;"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.dateTo"
                      @input="modalTo = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" md="1" xs="6">
          <v-btn rounded color="primary" style="float: right;" type="submit">
            <v-icon left>
              {{ iconSearch }}
            </v-icon>
            {{ $t("message.btn-search") }}
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </div>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
import moneyFormat from "../helpers/moneyFormat";
import { Money } from "v-money";

export default {
  name: "FilterOrder",
  data: () => ({
    modalFrom: false,
    modalTo: false,
    iconSearch: mdiMagnify,
    outlined: true,
    clearable: true,
    valueWhenIsEmpty: "",
    options: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    itemsStatus: [
      { id: 1, name: "カート" },
      { id: 2, name: "受注" },
      { id: 3, name: "出荷準備中" },
      { id: 4, name: "出荷準備完了" },
      { id: 5, name: "出荷完了" },
      { id: 6, name: "キャンセル" },
    ],
    filter: {
      status: "",
      merchant: "",
      orderId: "",
      dateFrom: "",
      dateTo: "",
      amountFrom: "",
      amountTo: "",
      store: "",
      page: "",
    },
    today: new Date(),
  }),
  computed: {
    merchantList() {
      return this.$store.state.approve_module.resultAllMerchant;
    },
    shopList() {
      return this.$store.state.order_module.resultShop;
    },
  },
  methods: {
    formatDate(date) {
      return new Date(new Date(date) - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },
    submitDateFrom() {
      this.modalFrom = false;
      this.$refs.dialog.save(this.dateFrom);
    },
    submitDateTo() {
      this.modalTo = false;
      this.$refs.dialog.save(this.dateTo);
    },
    submitFilter() {
      this.$store.dispatch("order_module/fetchOrderList", {
        status: this.filter.status === null ? "" : this.filter.status,
        merchant: this.filter.merchant === null ? "" : this.filter.merchant,
        orderId: this.filter.orderId === null ? "" : this.filter.orderId,
        dateFrom: this.filter.dateFrom === null ? "" : this.filter.dateFrom,
        dateTo: this.filter.dateTo === null ? "" : this.filter.dateTo,
        amountFrom:
          this.filter.amountFrom === null ? "" : this.filter.amountFrom,
        amountTo: this.filter.amountTo === null ? "" : this.filter.amountTo,
        shop: this.filter.store === null ? "" : this.filter.store,
        page: 1,
      });
    },
    async dispatchFilter() {
      this.$store.dispatch("order_module/fetchShop");
      await this.$store.dispatch("approve_module/fetchAllMechant");

      if (this.$store.state.order_module.fDateFrom === "") {
        this.filter.dateFrom = this.formatDate(this.today);
      } else {
        this.filter.dateFrom = this.$store.state.order_module.fDateFrom;
      }

      this.filter.merchant = this.$store.state.order_module.fMerchant;
      this.filter.status = this.$store.state.order_module.fStatus;
      this.filter.store = this.$store.state.order_module.fShop;
      this.filter.orderId = this.$store.state.order_module.fOrderId;
      this.filter.dateTo = this.$store.state.order_module.fDateTo;
      this.filter.amountFrom = this.$store.state.order_module.fAmountFrom;
      this.filter.amountTo = this.$store.state.order_module.fAmountTo;
      this.filter.page = this.$store.state.order_module.page_temp;

      this.$store.dispatch("order_module/fetchOrderList", {
        merchant: this.filter.merchant,
        status: this.filter.status,
        shop: this.filter.store,
        orderId: this.filter.orderId,
        dateFrom: this.filter.dateFrom,
        dateTo: this.filter.dateTo,
        amountFrom: this.filter.amountFrom,
        amountTo: this.filter.amountTo,
        page: this.filter.page,
      });
    },
  },
  created() {
    this.dispatchFilter();
  },
};
</script>

<style>
.btn-search {
  border-radius: 20px;
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 0px !important;
}
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 0px !important;
  align-self: center !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 0px !important;
  align-self: center !important;
}
.col-filter-input {
  padding-right: 10px !important;
  padding-left: 0px !important;
}
.text-field-class .v-input__control input {
  font-size: 12px;
}
.minus-amount-order .icon-tilde-class-order {
  font-size: 15px;
  margin-left: 1px;
  margin-right: 1px;
  margin-top: 1px;
}
.label-class {
  font-size: 12px;
}
.minus-amount {
  max-width: fit-content !important;
}
.minus-amount .icon-tilde-class {
  font-size: 15px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 8px;
}
@media only screen and (max-width: 640px) {
  .btn-search-filter-customer {
    float: none !important;
  }
  .col-mobile-width {
    width: 50% !important;
  }
}
</style>
