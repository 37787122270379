<template>
  <v-container>
    <FilterOrder />

    <!-- data table -->
    <v-pagination
      v-if="!isLength"
      v-model="page"
      :length="lengthPage"
      :total-visible="7"
      @input="changePage"
      depressed
    ></v-pagination>
    <v-data-table
      :headers="headers"
      :items="orderList"
      :hide-default-footer="true"
      class="elevation-0"
      disable-sort
      justify="center"
    >
      <!-- action table -->
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          @click="toOrderDetail(item.id)"
          color="indigo"
          small
          class="mr-2"
        >
          mdi-eye
        </v-icon>
      </template>

      <!-- ADD CLICKABLE REDIRECT BY ORDER NUMBER -->
      <template v-slot:[`item.order_no`]="{ item }">
        <a href="" @click.prevent="toOrderDetail(item.id)">{{
          item.order_no
        }}</a>
      </template>

      <!-- <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          :items-per-page-text="$t('message.rows-per-page')"
          class="elevation-0 v-data-footer-top"
          :disable-items-per-page="true"
        />
      </template> -->
    </v-data-table>
  </v-container>
</template>

<script>
import FilterOrder from "../components/FilterOrder.vue";
import moneyFormat from "../helpers/moneyFormat";
export default {
  components: { FilterOrder },
  name: "OrderManagement",
  data: () => ({
    option: {},
    page: 1,
    loading: false,
  }),
  watch: {
    orderList: {
      handler() {
        this.page = parseInt(this.$store.state.order_module.page);
      },
      deep: true,
    },
  },
  computed: {
    isLength() {
      return this.$store.state.order_module.is_length;
    },
    lengthPage() {
      return this.$store.state.order_module.length_page;
    },
    orderList() {
      let orderData = this.$store.state.order_module.resultOrder;
      if (orderData) {
        orderData.map((order) => {
          order.total_order_price = moneyFormat(
            parseInt(order.total_order_price)
          );
        });
      }
      return orderData;
    },
    orderHeader() {
      return this.$store.state.order_module.resultOrderHeader;
    },
    headers() {
      return [
        {
          text: this.$t("message.label-merchant"),
          align: "start",
          value: "merchant_name",
        },
        {
          text: this.$t("message.label-shop"),
          value: "store_name",
          align: "center",
        },
        {
          text: this.$t("message.label-order-id"),
          value: "order_no",
          align: "center",
        },
        {
          text: this.$t("message.label-customer-amount"),
          value: "total_order_price",
          align: "center",
        },
        {
          text: this.$t("message.label-order-datetime"),
          value: "created_at",
          align: "center",
        },
        {
          text: this.$t("message.label-status"),
          value: "status",
          align: "center",
        },
        {
          text: this.$t("message.label-balance_status"),
          align: "center",
          value: "balance_status",
        },
        {
          text: this.$t("message.label-balance_amount"),
          align: "center",
          value: "balance_amount",
        },
        {
          text: this.$t("message.label-action"),
          value: "action",
          align: "center",
        },
      ];
    },
  },
  methods: {
    changePage(event) {
      return this.$store.dispatch("order_module/fetchOrderList", {
        page: event,
      });
    },
    dispatchOrderList() {
      // const { page } = this.option;
      let pageInit = parseInt(this.$store.state.order_module.page);
      this.page = parseInt(this.$store.state.order_module.page);
      this.$store.dispatch("order_module/fetchOrderList", { page: pageInit });
    },
    toOrderDetail(id) {
      //this.$router.push(`/orderDetail/${id}`);
      this.$router.push({
        name: "OrderDetail",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>

<style>
v-data-table >>> div > table > tr {
  border-spacing: 5px;
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}
thead tr th {
  color: white !important;
  font-size: 15px !important;
}
tbody tr:hover:not(.v-table__expanded__content) {
  /* background: #c5cae9 !important; */
}
.v-application--is-ltr .v-data-footer__select {
  margin-left: 0px !important;
}
.v-data-footer-top {
  border-top: none !important;
}
</style>
